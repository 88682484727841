/**
 * Handle the stay signed in request feature once the DOM has finished loading
 */
import {getCookie} from "../utilities/cookies";
import FetchHelpers from "../fetch-helpers";
import {AsyncNotice} from "../notices/notice";

document.addEventListener('DOMContentLoaded', async () => {
  await handleStaySignedInRequest();
});


/**
 * Check if user has enabled the 'stay logged in' feature. If so:
 * - check if the user has a cookie set with a login hash.
 * - if cookie present, check if there is an account with a matching cookie.
 * - if match is found, sign user in.
 *
 * @returns {Promise<void>}
 */
async function handleStaySignedInRequest() {
  const signInElem = document.querySelector('#signin-dropdown');
  if (signInElem) {
    const key = getCookie('sh');
    if (!key) {
      return;
    }

    // check if we just signed out or should attempt to sign-in
    const { dataset: { url } } = signInElem;
    const result = await new FetchHelpers().fetchData('GET', url);

    let { success, signingOut } = result;
    let notice, message;

    if(success) {
      if (!signingOut) {
        message = 'Signing in...';
        notice = new AsyncNotice(message, 'notice', false, 10);
        notice.remove();

        await notice.create({}, true);

        window.location.assign('/account');
      }
    } else {
      const message = 'Could not sign you in automatically. Please sign in with your credentials.';
      notice = new AsyncNotice(message, 'error', false, 5);
      await notice.create({}, true);
      notice.remove();
    }
  }
}
