import {asyncSleep} from "../utilities/general";
import FetchHelpers from "../fetch-helpers";

/**
 * Send a notice to the main notices div from JS.
 *
 * Scrolls the screen to the top so the user see the notice.
 * @param message
 * @param type // 'info', 'warning', or 'error'
 * @param isSticky
 * @param duration
 * @param context
 * @constructor
 */
function AsyncNotice(message, type = 'info', isSticky = true, duration = 2, context = []) {
  // if type entered is not valid, default to 'info' type
  this.type = (['info', 'warning', 'error'].includes(type)) ? `notice-${type}` : 'notice-info';
  this.message = message;
  this.sticky = isSticky;
  this.duration = duration; // seconds
  this.context = context;

  this.logUrl = document.querySelector('.section-content')?.dataset?.logUrl;

  this.create = async function ( logContext = {}, show = true) {
    let noticesDiv = document.querySelector('#notices-main');

    if (noticesDiv) {
      noticesDiv.remove();
      await asyncSleep(50);
      noticesDiv = await this._createNotice();
    } else {
      noticesDiv = this._createNotice();
    }

    // send log to server logs for all notices that are not informational notices
    if (type !== 'info') {
      await this.log(logContext);
    }

    if(show) {
      document.querySelector('#sticky-wrapper').insertAdjacentElement('afterend', noticesDiv);
      window.scrollTo({top: 0, behavior: 'auto'});
    }
  }

  /**
   * Create a new notices div with a given message
   * @returns {HTMLDivElement}
   * @private
   */
  this._createNotice = function () {
    let noticesDiv, div, button, p, span;

    noticesDiv = document.createElement('div');
    noticesDiv.id = 'notices-main';
    noticesDiv.classList.add('output-notices');

    div = document.createElement('div');
    div.id = 'notice';

    // classes
    div.classList.add('callout', 'notice', this.type);
    if (this.sticky) {
      div.classList.add('not-set');
    }

    div.setAttribute('data-closable', '');

    // close button
    button = document.createElement('button');
    button.classList.add('close-button');
    button.setAttribute('aria-label', 'Dismiss alert');
    button.setAttribute('type', 'button');
    button.setAttribute('data-close', '');
    button.addEventListener('click', (event) => {
      event.stopImmediatePropagation();
      this.remove(5);
    });

    // 'x' span
    span = document.createElement('span');
    span.setAttribute('aria-hidden', 'true');
    span.innerHTML = '&times;';

    button.append(span);
    div.append(button);

    // message paragraph
    p = document.createElement('p');
    p.append(this.message);
    div.append(p);

    noticesDiv.append(div);

    return noticesDiv;
  }

  /**
   * Remove the current notice from the DOM. Defaults to removing notice within 2 seconds, if no value is passed.
   *
   * @param seconds
   */
  this.remove = function (seconds = null) {
    // favor secons param here; if null, use this.duration value, which has a default
    let milliseconds = (seconds ?? this.duration) * 1000;

    let noticesDiv = document.querySelector('#notices-main');

    if (noticesDiv) {
      noticesDiv.style.opacity = '1.0';
      noticesDiv.style.transition = "opacity " + seconds + "s ease";

      let fade = 'false';
      let appSettings = document.getElementsByName('app-settings');
      if (appSettings.length > 0) {
        appSettings = JSON.parse(appSettings[0].dataset.settings);
        fade = appSettings.FLASH_NOTICE_FADEOUT ?? fade;
      }

      if (fade === 'true') {
        noticesDiv.style.opacity = '0';
      }

      setTimeout(() => {
        noticesDiv.style.opacity = '1';
        noticesDiv.remove();
      }, milliseconds);
    }
  }

  /**
   * Log the notice to the server log. Gets root url for PHP target from `.content-section` element.
   * If missing, simply logs to console.
   *
   * @returns {Promise<void>}
   * @param logContext
   */
  this.log = async function (logContext = {}) {
    if (this.logUrl) {
      let context = logContext ? logContext : this.context;
      let message = logContext?.error ? logContext.error.message : this.message;
      let data = { message, context};
      let result = await new FetchHelpers().fetchData('POST', this.logUrl, data);

      if (result && !result.success) {
        let msg = `Could not log the following error:\n${message}`;
        console.log(`${msg}`);
      }
    } else {
      console.log('Log url missing.');
    }
  }
}

export {AsyncNotice};
