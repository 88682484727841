/**
 * Utility methods
 */

import {AsyncNotice} from "../notices/notice";

/**
 * Remove the CW page numbers (e.g., "[23]" or "[iv]" from text being copied to the clipboard.
 * @param text
 * @returns {*}
 */
function stripPageNumbers(text) {
    if(text) {
        // match a pageNumber and the following space
        let regex = /(\[[0-9]*[ivxlc]*\])/ig

        let newText = text.replace(regex, '');

        // remove extra whitespace if any double-white spaces found
        return newText.replace('  ', ' ').trim();
    }

    return text;
}

/**
 * Copy text to clipboard
 * @param textToCopy
 * @returns {Promise<unknown>|Promise<void>}
 */
// returns a promise
async function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);

        // focus and select textarea content
        textArea.focus();
        textArea.select();
        textArea.setSelectionRange(0, textArea.textLength);
        try {
            // copy selected text in textArea
            document.execCommand('copy');
        } catch (error) {
            const notice = new AsyncNotice('Error copying to clipboard');
            await notice.create({error});
            notice.remove();
        }
        textArea.remove();
    }
}

/**
 * Generate a range of numbers
 * @param start
 * @param end
 * @returns {unknown[]}
 */
function range(start, end) {
    return Array(end - start + 1).fill(0, 0, end).map((_, idx) => start + idx);
}

/**
 * Asynchronous sleep method
 * @param ms milliseconds for setTimeout()
 * @param fn if present, callback to run after setTimeout() finishes
 * @returns {Promise<unknown>}
 */
function asyncSleep(ms, fn = null) {
    if(fn) {
       return new Promise((fn => setTimeout(fn, ms)))
    }

    return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Capitalize a string
 */
function capitalize(string) {
    if(typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Scroll to page number searched for or reading portion start.
 * This method is meant to be used with the Page Search feature in the reader or
 * with reading portion requests from apps.
 * @param anchorLocation
 */
function scrollToAnchor(anchorLocation) {
    let anchor, span, html;
    if(anchorLocation === 'start') {
        anchor = document.querySelector('.start');
    } else {
        anchor = document.querySelector(`[data-page-id='${anchorLocation}'`);

        if(anchor.nodeName === 'P') {
            span = document.createElement('span');
            html = anchor.innerHTML;

            span.setAttribute('id', anchor.id);
            span.dataset.pageId = anchor.id;
            span.classList.add('pg');
            span.classList.add('page-anchor');
            span.innerHTML = html;

            anchor.innerHTML = '';
            anchor.appendChild(span);

            // set the anchor to the new span inside the paragraph element
            anchor = anchor.firstChild;
        } else {
            anchor.classList.add('page-anchor');
        }
    }

    if(anchor) {
        anchor.scrollIntoView( {block: "center" });

        // remove special styling after some seconds
        const timeout = appSettings().PAGE_NUMBER_HIGHLIGHT_DURATION;
        setTimeout(function(anchor) {
            return function() {
                anchor.classList.remove('page-anchor');
            };
        }(anchor), timeout);
    }
}

/**
 * Turn a rgb color into an object.
 *
 * @param rgbColor
 * @returns {*}
 */
function rgbToObject(rgbColor) {
    // check if rgb number separator is comma
    let rgb = rgbColor.replace(/[rgba()]/g, '');
    let parts = rgb.split(',');

    // if separator is not a comma
    (parts.length === 1) ? parts = rgb.split(' ') : null;

    let color = [];
    color.red = parseInt(parts[0].trim());
    color.green = parseInt(parts[1].trim());
    color.blue = parseInt(parts[2].trim());

    color.alpha = parts.length === 4 ? parseFloat(parts[3].trim()) : null;

    return color;
}

/**
 * turn an object representing a rgb color into a rgb() color.
 *
 * You can select the output rgb() color string separator (a comma, or a space)
 *
 * returns rgb() not rbga()
 *
 * @param colorObject
 * @param separator
 */
function objectToRgb(colorObject, separator=',') {
    const c = colorObject;
    const s = (separator === ',') ? separator + ' ' : separator;

    const red = c.red;
    const greenClause = `${s}${c.green}`;
    const blueClause = `${s}${c.blue}`;
    let alphaClause = c.alpha ? `${s}${c.alpha}` : null;

    return `rgb(${red}${greenClause}${blueClause}${alphaClause});`;
}

/**
 * Turn a rgb (or rgba) color to hex.
 *
 * @param rgb
 * @returns {{hex: string, opacity: string}}
 */
function rgbToHex(rgb) {
    // e.g., rgb(0,128, 255, 0.5) -> 'parts' will be (0,128, 255, 0, 5)
    let parts = rgb.match(/\d+/g);
    let hex = '#' + parts.map((x) => {
        x = parseFloat(x).toString(16);
        return (x.length === 1) ? "0" + x : x;
    }).join("");

    let obj = {hex: hex.substring(0,7), opacity: '1'};

    // handle opacity
    switch(parts.length) {
        case 4:
            obj.opacity = parts[3].toString();
            break;
        case 5:
            obj.opacity = `${parts[3]}.${parts[4]}`.toString();
    }

    return obj;
}

/**
 * Scroll to an element on the page and temporarily add shadow to element.
 *
 * @param element
 * @param spotlight
 */
function scrollAndSpotlight(element, spotlight = true) {
    // spotlight the annotation
    if(spotlight) {
        element.classList.add('target-highlight');

        // remove shadow after delay
        const duration = parseInt(getHighlightSpotlightDuration()) * 1000; // in ms
        setTimeout(function(element) {
            return function() {
                element.classList.remove('target-highlight');
            };
        }(element), duration);
    }

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

/**
 * Programmatically add a CSS style to the page.
 *
 * @param styles
 * @param styleSheetTitle
 * @param index
 */
function addStyle(styles, styleSheetTitle = null, index = 0) {
    const sheets = document.styleSheets;
    let sheet;

    if (styleSheetTitle) {
        // for (let i = 0; i < sheets.length; i++) {
        for(const thisSheet of sheets) {
            if (thisSheet.title === styleSheetTitle) {
               sheet = thisSheet;
               break;
            }
        }
    }

    // if not found, use first sheet
    if(!sheet) {
        sheet = sheets[0];
    }

    sheet.insertRule(styles, index);
}

/**
 * Check if the given element is hidden in DOM.
 *
 * Uses 'offsetParent': https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement.offsetParent
 * @param el
 * @returns {boolean}
 */
function isHidden(el) {
    return (el.offsetParent === null)
}
/**
 * Return user preferences array stored in preferences meta tag
 * @returns {*|null}
 */
function userPreferences() {
    let name = 'user-preferences';
    if(document.querySelector('meta[name="s-user-preferences"]')) {
       name = 's-user-preferences';
    }

    return fetchDataProperty('preferences', null, name);
}

/**
 * Return array of colors and their values set in the Settings class and fetched from the Colors meta tag
 * @returns {*|null}
 */
function appColors() {
    return fetchDataProperty('colors', null, 'colors');
}

/**
 * Returns app settings from the app settings meta tag
 * @returns {*|null}
 */
function appSettings() {
    return fetchDataProperty('settings', null, 'app-settings');
}

/**
 * Getter - returns anchor container from app settings.
 *
 * @returns {*}
 */
function getAnchorContainer() {
    return appSettings().ANCHOR_CONTAINER;
}

/**
 * Getter - highlight spotlight duration.
 *
 * @returns {*}
 */
function getHighlightSpotlightDuration() {
    return appSettings().HIGHLIGHT_SPOTLIGHT_DURATION;
}

/**
 * Returns the current content id form the current-info element
 * @returns {*|null}
 */
function getCurrentContentId() {
    return fetchDataProperty('contentId', 'current-info');
}

/**
 * Returns the current section id from the current-info element
 * @returns {*|null}
 */
function getCurrentSectionId() {
    return fetchDataProperty('sectionId', 'current-info');
}

/**
 * Returns the current HTML content id from the current-info element
 * @returns {*|null}
 */
function getCurrentHtmlContentId() {
    return fetchDataProperty('htmlContentId', 'current-info');
}

function getCurrentUserId() {
    return fetchDataProperty('userId', 'current-info');
}

/**
 * Helper method: fetch the data specified data property from the given element. If no element given,
 * use the provided meta element name to search for the given meta tag.
 * Return null if no element found.
 * @param propertyName
 * @param elementClass
 * @param metaName
 * @returns {null|*}
 */
function fetchDataProperty(propertyName, elementClass, metaName = null) {
    let elem, response;
    if (elementClass) {
        elem = document?.querySelector(`.${elementClass}`);
        response = elem?.dataset[propertyName];
    } else if (metaName) {
        elem = document?.querySelector(`meta[name='${metaName}']`);
        let data = elem?.dataset[propertyName];

        response = data ? JSON.parse(data) : {};

    } else {
        console.log(`no element found with class ${elementClass} or meta element with name ${metaName}`);
    }

    // JSON parse the property value
    if(response){
        return response;
    }
    else {
        return null;
    }
}

/**
 * Get the key for a value in an object
 * @param object
 * @param value
 * @returns {string}
 */
function getKeyByValue(object, value) {
    let result = Object.keys(object).find(key => object[key] === value);
    return result === undefined ? null : result;
}

/**
 * Update a property in the user preferences meta element.
 *
 * @param propertyName
 * @param value
 */
function updateUserPreferenceProperty(propertyName, value) {
    let elem = document.querySelector("meta[name='user-preferences']");
    if(elem) {
        let preferences = JSON.parse(elem.dataset.preferences);
        if(preferences.hasOwnProperty(propertyName)) {
            preferences[propertyName] = value;
        }
        elem.dataset.preferences = JSON.stringify(preferences);
    } else {
        console.log('Could not update the user preference property');
    }
}

/**
 * Detect if an element is within the visible viewport.
 * @param el
 * @returns {boolean}
 */
function isInView(el) {
    if(el) {
        const box = el.getBoundingClientRect();
        return box.top < window.innerHeight && box.bottom >= 0;
    } else {
        return false;
    }
}

function getUniqueWords(text) {
    let unique;

    // replace symbols and punctuation with whitespace
    unique = text.replace(/[.,?;:!)(\[\]]/g, ' ');

    // trim text, reduce multiple adjacent whitespaces to one, then replace with comma
    unique
      = unique
      .trim()
      .replace(/(\s)+/g, ' ')
      .replace(/\s/g, ',');

    // filter out duplicates
    unique = unique.split(',').filter(onlyUnique);

    return unique;
}

/**
 * Checks if a value is unique in an array based on its index position.
 *
 * @param {*} value - The value to check for uniqueness.
 * @param {number} index - The index of the current value in the array.
 * @param {Array} array - The array to check for uniqueness.
 * @returns {boolean} - True if the value is unique, false otherwise.
 */
function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
}


/**
 * Check if the object is iterable.
 *
 * @param object
 * @returns {boolean}
 */
function isIterable(object) {
  if(object === null) {
      return false;
  }

  return typeof object[Symbol.iterator] === 'function';
}

export { range, capitalize, scrollToAnchor, scrollAndSpotlight, appSettings, appColors,
         userPreferences, getCurrentHtmlContentId, getCurrentContentId,
         getCurrentSectionId, getCurrentUserId, getKeyByValue, getAnchorContainer,
         getHighlightSpotlightDuration, getUniqueWords, copyToClipboard, stripPageNumbers,
         asyncSleep, isHidden, isInView, isIterable, rgbToObject, objectToRgb, rgbToHex,
        updateUserPreferenceProperty };
