/**
 *  JS fetch helpers
 */
function FetchHelpers() {
    /**
     * Fetch some data from the backend
     * @param verb
     * @param url
     * @param data
     * @returns {Promise<*>}
     */
    this.fetchData = async function(verb, url, data = {}) {
        let options = {
            method: verb,
            // mode: 'same-origin', // no-cors, *cors
            // cache: 'no-cache',
            // credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XmlHttpRequest'
            },
            // redirect: 'follow',
        }

        if (verb === 'POST') {
            options.body = JSON.stringify(data);
        }

        // console.log(`fetch url: ${url}`);
        return await fetch(url, options)
            .then(checkStatus)
            .then(parseJSON)
            .then(data => {
                if (data['error']) {
                    console.log()
                    console.log(`${data['error']}`);
                    return data;
                } else {
                    return data;
                }
            })
            .catch((error) => {
                console.log('request failed', error)
                document.body.style.cursor = 'default';
                data['success'] = false;
                data['error'] = error;

                return data;
            });
    }
    // private fetch API helper methods
    let checkStatus = function (response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            let error = Error(response.statusText)
            error.response = response;
            throw error;
        }
    }

    let parseJSON = function (response) {
        return response.json();
    }
}

export default FetchHelpers;
